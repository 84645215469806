import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch } from "../../../../hooks/redux";
import { setShowNotification } from "../../../../store/reducers/widgets/WidgetsSlice";

interface IContent {
    id: number;
    type: 'text' | 'video' | 'image' | 'heading' | 'pdf' | 'presentation';
    value: string | File;
}

interface ISection {
    id: number;
    title: string;
    content: IContent[];
    moduleId: number;
}

interface ModalEditSectionProps {
    isOpen: boolean;
    toggle: () => void;
    section: ISection;
    onSave: (updatedSection: ISection) => void;
}

const ModalEditSection: React.FC<ModalEditSectionProps> = ({ isOpen, toggle, section, onSave }) => {
    const dispatch = useAppDispatch();
    const [sectionTitle, setSectionTitle] = useState(section.title);
    const [content, setContent] = useState<IContent[]>(section.content);
    const [newContentType, setNewContentType] = useState<'text' | 'video' | 'image' | 'heading' | 'pdf' | 'presentation'>('text');
    const [newContentValue, setNewContentValue] = useState<string | File>('');
    const [titleError, setTitleError] = useState<string | null>(null);
    const [contentError, setContentError] = useState<string | null>(null);
    const [editingContentId, setEditingContentId] = useState<number | null>(null);

    const validateSection = () => {
        let isValid = true;

        if (!sectionTitle.trim()) {
            setTitleError('Название раздела не может быть пустым!');
            isValid = false;
        } else {
            setTitleError(null);
        }

        return isValid;
    };

    const validateContent = () => {
        let isValid = true;

        if (newContentType === 'pdf' || newContentType === 'presentation' || newContentType === 'image') {
            if (!newContentValue) {
                setContentError('Выберите файл для загрузки!');
                isValid = false;
            } else {
                setContentError(null);
            }
        } else if (typeof newContentValue === 'string' && !newContentValue.trim()) {
            setContentError('Введите значение для контента!');
            isValid = false;
        } else {
            setContentError(null);
        }

        return isValid;
    };

    const handleAddOrUpdateContent = () => {
        if (!validateContent()) return;

        if (editingContentId !== null) {
            setContent(content.map(item =>
                item.id === editingContentId ? { ...item, type: newContentType, value: newContentValue } : item
            ));
            setEditingContentId(null);
        } else {
            const newContent: IContent = {
                id: Date.now(),
                type: newContentType,
                value: newContentValue,
            };
            setContent([...content, newContent]);
        }

        setNewContentValue('');
        setNewContentType('text');
        setContentError(null);
    };

    const handleDeleteContent = (contentId: number) => {
        setContent(content.filter(item => item.id !== contentId));
        if (editingContentId === contentId) {
            setEditingContentId(null);
            setNewContentValue('');
            setNewContentType('text');
        }
    };

    const handleEditContent = (item: IContent) => {
        setEditingContentId(item.id);
        setNewContentType(item.type);
        setNewContentValue(item.value);
    };

    const handleSave = () => {
        if (!validateSection()) return;
        const updatedSection: ISection = {
            ...section,
            title: sectionTitle,
            content,
        };
        onSave(updatedSection);
        toggle();
        dispatch(setShowNotification({ type: "success", message: `Раздел сохранён`, show: true }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setNewContentValue(file);
        }
    };

    const columns = [
        {
            name: <span className="font-weight-bold">Тип контента</span>,
            selector: (row: IContent) => row.type,
            cell: (row: IContent) => (
                <span>
                    {row.type === 'text' ? 'Текст' :
                        row.type === 'video' ? 'Видео' :
                            row.type === 'image' ? 'Картинка' :
                                row.type === 'heading' ? 'Заголовок' :
                                    row.type === 'pdf' ? 'PDF-файл' :
                                        'Презентация'}
                </span>
            ),
            width: '150px',
        },
        {
            name: <span className="font-weight-bold">Информация</span>,
            selector: (row: IContent) => (typeof row.value === 'string' ? row.value : row.value.name),
            cell: (row: IContent) => (
                <span>
                    {typeof row.value === 'string' ? row.value : row.value.name}
                </span>
            ),
            wrap: true,
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: IContent) => (
                <i
                    className="bi bi-pencil fs-5 text-black cursor-pointer"
                    onClick={() => handleEditContent(row)}
                    title="Редактировать контент"
                />
            ),
            width: '80px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: IContent) => (
                <i
                    className="bi bi-trash fs-5 text-danger cursor-pointer"
                    onClick={() => handleDeleteContent(row.id)}
                    title="Удалить контент"
                />
            ),
            width: '80px',
        },
    ];

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
            <ModalHeader
                className="p-3 bg-soft-primary d-flex justify-content-between align-items-center"
                toggle={toggle}
            >
                Редактирование раздела
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="sectionTitle">Название раздела</Label>
                        <Input
                            type="text"
                            id="sectionTitle"
                            value={sectionTitle}
                            onChange={(e) => {
                                setSectionTitle(e.target.value);
                                if (titleError && e.target.value.trim()) {
                                    setTitleError(null);
                                }
                            }}
                            placeholder="Введите название раздела"
                            invalid={!!titleError}
                        />
                        {titleError && (
                            <div className="text-danger">
                                {titleError}
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label>{editingContentId !== null ? 'Редактировать контент' : 'Добавить новый контент'}</Label>
                        <div className="d-flex align-items-center mb-2">
                            <Input
                                type="select"
                                value={newContentType}
                                onChange={(e) => {
                                    setNewContentType(e.target.value as 'text' | 'video' | 'image' | 'heading' | 'pdf' | 'presentation');
                                    setNewContentValue('');
                                }}
                                className="me-2"
                                style={{ width: '150px' }}
                            >
                                <option value="heading">Заголовок</option>
                                <option value="text">Текст</option>
                                <option value="image">Картинка</option>
                                <option value="video">Видео</option>
                                <option value="pdf">PDF-файл</option>
                                <option value="presentation">Презентация</option>
                            </Input>
                            {newContentType === 'pdf' || newContentType === 'presentation' || newContentType === 'image' ? (
                                <Input
                                    type="file"
                                    accept={
                                        newContentType === 'pdf' ? '.pdf' :
                                            newContentType === 'presentation' ? '.ppt,.pptx' :
                                                'image/*'
                                    }
                                    onChange={handleFileChange}
                                    className="me-2"
                                    invalid={!!contentError}
                                />
                            ) : (
                                <Input
                                    type={newContentType === 'text' || newContentType === 'heading' ? 'textarea' : 'text'}
                                    value={typeof newContentValue === 'string' ? newContentValue : ''}
                                    onChange={(e) => setNewContentValue(e.target.value)}
                                    placeholder={
                                        newContentType === 'text' ? 'Введите текст' :
                                            newContentType === 'video' ? 'Введите ссылку на видео' :
                                                newContentType === 'heading' ? 'Введите заголовок' :
                                                    ''
                                    }
                                    className="me-2"
                                    invalid={!!contentError}
                                />
                            )}
                            <Button
                                color="primary"
                                onClick={handleAddOrUpdateContent}
                            >
                                {editingContentId !== null ? 'Изменить' : 'Добавить'}
                            </Button>
                        </div>
                        {contentError && (
                            <div className="text-danger">
                                {contentError}
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label>Контент раздела</Label>
                        <DataTable
                            columns={columns}
                            data={content}
                            noDataComponent="Нет контента"
                            highlightOnHover
                            customStyles={{
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5",
                                    },
                                },
                            }}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalEditSection;