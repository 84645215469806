import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface ModalDeleteModuleProps {
    isOpen: boolean;
    toggle: () => void;
    onDelete: () => void;
    moduleTitle: string;
}

const ModalDeleteModule: React.FC<ModalDeleteModuleProps> = ({ isOpen, toggle, onDelete, moduleTitle }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader
                className="p-3 bg-soft-primary d-flex justify-content-between align-items-center"
                toggle={toggle}
            >
                Подтверждение удаления
            </ModalHeader>
            <ModalBody>
                <p>
                    Вы уверены, что хотите удалить модуль "{moduleTitle}" и все его разделы?
                    Это действие нельзя отменить.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onDelete}>
                    Удалить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalDeleteModule;