import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Input, Label, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import DataTable, { TableColumn } from "react-data-table-component";
import ModalCreateTestTask from "../../components/education/PlaningEducation/modal/ModalCreateTestTask";
import ModalCreateSection from "../../components/education/PlaningEducation/modal/ModalCreateSection";
import ModalDeleteSection from "../../components/education/PlaningEducation/modal/ModalDeleteSection";
import NotificationShow from "../../components/widgets/NotificationShow";

interface IAnswer {
    id: number;
    text: string;
    isCorrect: boolean;
}

interface ISection {
    id: number;
    title: string;
    videoLink?: string;
    pdfFile?: File;
    powerPointFile?: File;
}

interface ITestTask {
    id: number;
    title: string;
    type: 'single' | 'multiple' | 'sorting' | 'video' | 'pdf' | 'powerpoint';
    status: 'active' | 'inactive';
    answers?: IAnswer[];
    shuffleAnswers?: boolean;
    points?: number;
    sectionId?: number;
    videoLink?: string;
    pdfFile?: File;
    powerPointFile?: File;
}

type TableRow = ISection | ITestTask;

const CreateTestTasks: React.FC = () => {
    const { testId } = useParams<{ testId: string }>();
    const location = useLocation();
    const { testTitle } = (location.state as { testTitle: string }) || { testTitle: 'Неизвестный тест' };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [sectionToDelete, setSectionToDelete] = useState<number | null>(null);
    const [taskToEdit, setTaskToEdit] = useState<ITestTask | undefined>(undefined);
    const [sectionToEdit, setSectionToEdit] = useState<ISection | undefined>(undefined);

    const initialSections: ISection[] = [];
    const initialTasks: ITestTask[] = [];

    const [sections, setSections] = useState<ISection[]>(initialSections);
    const [tasks, setTasks] = useState<ITestTask[]>(initialTasks);
    const [filteredTasks, setFilteredTasks] = useState<TableRow[]>([]);
    const [search, setSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [typeFilter, setTypeFilter] = useState('all');
    const [sectionFilter, setSectionFilter] = useState<string>('all');
    const [sortField, setSortField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        let filtered = [...tasks];

        if (search) {
            filtered = filtered.filter(task =>
                task.title.toLowerCase().includes(search.toLowerCase())
            );
        }

        if (statusFilter !== 'all') {
            filtered = filtered.filter(task => task.status === statusFilter);
        }

        if (typeFilter !== 'all') {
            filtered = filtered.filter(task => task.type === typeFilter);
        }

        if (sectionFilter !== 'all') {
            if (sectionFilter === 'no-section') {
                filtered = filtered.filter(task => !task.sectionId);
            } else {
                filtered = filtered.filter(task => task.sectionId === Number(sectionFilter));
            }
        }

        const tableData: TableRow[] = [];
        sections.forEach(section => {
            let sectionTasks = filtered.filter(task => task.sectionId === section.id);
            if (sectionFilter === 'all' || sectionFilter === String(section.id)) {
                if (sortField) {
                    sectionTasks = [...sectionTasks].sort((a, b) => {
                        let comparison = 0;
                        switch (sortField) {
                            case 'title':
                                const titleA = a.title.toLowerCase();
                                const titleB = b.title.toLowerCase();
                                comparison = titleA > titleB ? 1 : titleA < titleB ? -1 : 0;
                                break;
                            case 'type':
                                const typeA = a.type || '';
                                const typeB = b.type || '';
                                comparison = typeA > typeB ? 1 : typeA < typeB ? -1 : 0;
                                break;
                            case 'status':
                                const statusA = a.status || '';
                                const statusB = b.status || '';
                                comparison = statusA > statusB ? 1 : statusA < statusB ? -1 : 0;
                                break;
                            case 'answers':
                                const answersA = a.answers ? a.answers.length : 0;
                                const answersB = b.answers ? b.answers.length : 0;
                                comparison = answersA - answersB;
                                break;
                            default:
                                break;
                        }
                        return sortDirection === 'asc' ? comparison : -comparison;
                    });
                }
                tableData.push(section);
                tableData.push(...sectionTasks);
            }
        });

        let tasksWithoutSection = filtered.filter(task => !task.sectionId);
        if (tasksWithoutSection.length > 0 && (sectionFilter === 'all' || sectionFilter === 'no-section')) {
            if (sortField) {
                tasksWithoutSection = [...tasksWithoutSection].sort((a, b) => {
                    let comparison = 0;
                    switch (sortField) {
                        case 'title':
                            const titleA = a.title.toLowerCase();
                            const titleB = b.title.toLowerCase();
                            comparison = titleA > titleB ? 1 : titleA < titleB ? -1 : 0;
                            break;
                        case 'type':
                            const typeA = a.type || '';
                            const typeB = b.type || '';
                            comparison = typeA > typeB ? 1 : typeA < typeB ? -1 : 0;
                            break;
                        case 'status':
                            const statusA = a.status || '';
                            const statusB = b.status || '';
                            comparison = statusA > statusB ? 1 : statusA < statusB ? -1 : 0;
                            break;
                        case 'answers':
                            const answersA = a.answers ? a.answers.length : 0;
                            const answersB = b.answers ? b.answers.length : 0;
                            comparison = answersA - answersB;
                            break;
                        default:
                            break;
                    }
                    return sortDirection === 'asc' ? comparison : -comparison;
                });
            }
            tableData.push({ id: 0, title: "Без секции" } as ISection);
            tableData.push(...tasksWithoutSection);
        }

        setFilteredTasks(tableData);
    }, [search, statusFilter, typeFilter, sectionFilter, tasks, sections, sortField, sortDirection]);

    const activeTasksCount = tasks.filter(task => task.status === 'active').length;

    const handleSort = (field: string) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const columns: TableColumn<TableRow>[] = [
        {
            name: <span className="font-weight-bold accessibility_size_table">№</span>,
            cell: (row: TableRow, rowIndex: number, column: TableColumn<TableRow>, id: string | number) => {
                if ('sectionId' in row) {
                    return ''; // Для задач не отображаем номер
                }
                // Считаем количество секций до текущей строки
                const sectionCount = filteredTasks
                    .slice(0, rowIndex)
                    .filter(item => !('sectionId' in item)).length + 1;
                return sectionCount;
            },
            width: '60px',
            style: {
                minWidth: '60px',
                maxWidth: '60px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },
        {
            name: (
                <span
                    className="font-weight-bold accessibility_size_table cursor-pointer"
                    onClick={() => handleSort('title')}
                >
                    Название {sortField === 'title' && (sortDirection === 'asc' ? '↑' : '↓')}
                </span>
            ),
            cell: (row: TableRow) => {
                if ('sectionId' in row) {
                    return <span className="accessibility_size_table" style={{ marginLeft: '40px' }}>{row.title}</span>;
                }
                return <span className="font-weight-bold accessibility_size_table">{row.title}</span>;
            },
            wrap: true,
        },
        {
            name: (
                <span
                    className="font-weight-bold accessibility_size_table cursor-pointer"
                    onClick={() => handleSort('type')}
                >
                    Тип {sortField === 'type' && (sortDirection === 'asc' ? '↑' : '↓')}
                </span>
            ),
            cell: (row: TableRow) => {
                if ('sectionId' in row) {
                    return (
                        <span className="text-muted accessibility_size_table">
                            {(() => {
                                switch (row.type) {
                                    case 'single':
                                        return 'Единственный выбор';
                                    case 'multiple':
                                        return 'Множественный выбор';
                                    case 'video':
                                        return 'Видеоформат';
                                    case 'pdf':
                                        return 'PDF файл';
                                    case 'powerpoint':
                                        return 'PowerPoint';
                                    default:
                                        return row.type;
                                }
                            })()}
                        </span>
                    );
                }
                return '';
            },
            width: '250px',
        },
        {
            name: (
                <span
                    className="font-weight-bold accessibility_size_table cursor-pointer"
                    onClick={() => handleSort('status')}
                >
                    Статус {sortField === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
                </span>
            ),
            cell: (row: TableRow) => {
                if ('sectionId' in row) {
                    return (
                        <span className="text-muted accessibility_size_table">
                            {row.status === 'active' ? 'Активно' : 'Не активно'}
                        </span>
                    );
                }
                return '';
            },
            width: '150px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: TableRow) => {
                if ('sectionId' in row) {
                    return (
                        <i
                            className="bi bi-pencil fs-5 text-black cursor-pointer"
                            onClick={() => handleEditTask(row as ITestTask)}
                        />
                    );
                } else {
                    return (
                        <i
                            className="bi bi-pencil fs-5 text-black cursor-pointer"
                            onClick={() => handleEditSection(row as ISection)}
                        />
                    );
                }
            },
            width: '80px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: TableRow) => {
                if ('sectionId' in row) {
                    return (
                        <i
                            className="bi bi-trash fs-5 text-danger cursor-pointer"
                            onClick={() => handleDeleteTask((row as ITestTask).id)}
                        />
                    );
                } else {
                    return row.id !== 0 ? (
                        <i
                            className="bi bi-trash fs-5 text-danger cursor-pointer"
                            onClick={() => {
                                setSectionToDelete(row.id);
                                setIsDeleteModalOpen(true);
                            }}
                        />
                    ) : null;
                }
            },
            width: '80px',
        },
    ];

    const handleEditTask = (task: ITestTask) => {
        setTaskToEdit(task);
        setIsModalOpen(true);
    };

    const handleDeleteTask = (taskId: number) => {
        setTasks(tasks.filter(task => task.id !== taskId));
    };

    const handleAddTask = () => {
        setTaskToEdit(undefined);
        setIsModalOpen(true);
    };

    const handleAddSection = () => {
        setSectionToEdit(undefined);
        setIsSectionModalOpen(true);
    };

    const handleEditSection = (section: ISection) => {
        setSectionToEdit(section);
        setIsSectionModalOpen(true);
    };

    const handleDeleteSection = (sectionId: number) => {
        setSections(prevSections => {
            const updatedSections = prevSections.filter(section => section.id !== sectionId);
            setTasks(prevTasks => prevTasks.filter(task => task.sectionId !== sectionId));
            return updatedSections;
        });
    };

    const handleSaveSection = (title: string, videoLink?: string, pdfFile?: File, powerPointFile?: File) => {
        if (sectionToEdit) {
            setSections(sections.map(section =>
                section.id === sectionToEdit.id ? { ...section, title, videoLink, pdfFile, powerPointFile } : section
            ));
        } else {
            const newSection: ISection = {
                id: Date.now(),
                title,
                videoLink,
                pdfFile,
                powerPointFile,
            };
            setSections([...sections, newSection]);
        }
        setSectionToEdit(undefined);
    };

    const handleSaveTask = (updatedTask: ITestTask) => {
        if (taskToEdit) {
            setTasks(tasks.map(task => (task.id === updatedTask.id ? updatedTask : task)));
        } else {
            setTasks([...tasks, updatedTask]);
        }
        setTaskToEdit(undefined);
        setIsModalOpen(false);
    };

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Создание заданий</title>
            </Helmet>
            <Container fluid>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">Создание заданий для теста: {testTitle}</h4>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col md={3}>
                                <Label>Поиск по названию:</Label>
                                <Input
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Введите название задания"
                                    className="form-control"
                                />
                            </Col>
                            <Col md={3}>
                                <Label>Фильтр по статусу:</Label>
                                <Input
                                    type="select"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="all">Все</option>
                                    <option value="active">Активно</option>
                                    <option value="inactive">Не активно</option>
                                </Input>
                            </Col>
                            <Col md={3}>
                                <Label>Фильтр по типу:</Label>
                                <Input
                                    type="select"
                                    value={typeFilter}
                                    onChange={(e) => setTypeFilter(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="all">Все</option>
                                    <option value="single">Единственный выбор</option>
                                    <option value="multiple">Множественный выбор</option>
                                    <option value="video">Видеоформат</option>
                                    <option value="pdf">PDF файл</option>
                                    <option value="powerpoint">PowerPoint</option>
                                </Input>
                            </Col>
                            <Col md={3}>
                                <Label>Фильтр по секции:</Label>
                                <Input
                                    type="select"
                                    value={sectionFilter}
                                    onChange={(e) => setSectionFilter(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="all">Все секции</option>
                                    <option value="no-section">Без секции</option>
                                    {sections.map(section => (
                                        <option key={section.id} value={section.id}>
                                            {section.title}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <div>
                            <DataTable
                                columns={columns}
                                data={filteredTasks}
                                noDataComponent="Нет заданий для отображения"
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Заданий на странице:',
                                    rangeSeparatorText: 'из',
                                }}
                                fixedHeader
                                highlightOnHover
                                customStyles={{
                                    headCells: {
                                        style: {
                                            minWidth: '60px',
                                            maxWidth: '60px',
                                        },
                                    },
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5",
                                        },
                                    },
                                }}
                                conditionalRowStyles={[
                                    {
                                        when: (row: TableRow) => !('sectionId' in row),
                                        style: {
                                            backgroundColor: '#f5f5f5',
                                            fontWeight: 'bold',
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-between align-items-center text-muted">
                        <div>
                            Кол-во тестовых заданий: {tasks.length} (активных: {activeTasksCount})
                        </div>
                        <div>
                            <Button color="primary" onClick={handleAddSection} className="me-2">
                                Добавить секцию
                            </Button>
                            <Button color="primary" onClick={handleAddTask}>
                                Добавить задание
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Container>
            <ModalCreateTestTask
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
                onSave={handleSaveTask}
                taskToEdit={taskToEdit}
                sections={sections}
            />
            <ModalCreateSection
                isOpen={isSectionModalOpen}
                toggle={() => setIsSectionModalOpen(false)}
                onSave={handleSaveSection}
                sectionToEdit={sectionToEdit}
            />
            <ModalDeleteSection
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(false)}
                onDelete={() => sectionToDelete !== null && handleDeleteSection(sectionToDelete)}
            />
            <NotificationShow />
        </div>
    );
};

export default CreateTestTasks;