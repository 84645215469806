import React, { useState, useEffect } from 'react';
import { Container, Card, CardHeader, CardBody, CardFooter, Row, Col, Input, Label } from "reactstrap";
import { Helmet } from "react-helmet";
import DataTable from "react-data-table-component";
import ModalCreateEducation from "../../components/education/PlaningEducation/modal/ModalCreateEducation";
import NotificationShow from "../../components/widgets/NotificationShow";
import { useNavigate } from 'react-router-dom';

import imgfront from '../../assets/images/planingEducation/pand.jpg';
import imgtest from '../../assets/images/planingEducation/test.png';
import imgsql from '../../assets/images/planingEducation/sql.jpg';
import imghr_super from '../../assets/images/planingEducation/hr_super.jpg';
import imghr_2 from '../../assets/images/planingEducation/hr_2.jpg';

interface IEducation {
    id: number;
    image: string;
    title: string;
    description: string;
    date: Date;
}

const EducationPage: React.FC = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editEducationData, setEditEducationData] = useState<IEducation | null>(null);
    const [search, setSearch] = useState('');
    const [filteredEducation, setFilteredEducation] = useState<IEducation[]>([]);

    const initialEducation: IEducation[] = [
        {
            id: 1,
            image: imghr_2,
            title: "Курс по HR-менеджменту",
            description: "Основы управления персоналом",
            date: new Date(2025, 3, 15),
        },
        {
            id: 2,
            image: imghr_super,
            title: "Продвинутый HR",
            description: "Углубленные техники управления",
            date: new Date(2025, 3, 10),
        },
        {
            id: 3,
            image: imgsql,
            title: "SQL для аналитиков",
            description: "Основы работы с базами данных",
            date: new Date(2025, 3, 5),
        },
        {
            id: 4,
            image: imgfront,
            title: "Frontend-разработка",
            description: "Основы React и JavaScript",
            date: new Date(2025, 3, 20),
        },
        {
            id: 5,
            image: imgtest,
            title: "Техники продаж",
            description: "Эффективные методы продаж",
            date: new Date(2025, 3, 15),
        },
    ];

    const [education, setEducation] = useState<IEducation[]>(initialEducation);

    useEffect(() => {
        let filtered = [...education];

        if (search) {
            filtered = filtered.filter(item =>
                item.title.toLowerCase().includes(search.toLowerCase())
            );
        }

        setFilteredEducation(filtered);
    }, [search, education]);

    const columns = [
        {
            name: <span className="font-weight-bold accessibility_size_table">Название</span>,
            selector: (row: IEducation) => row.title,
            cell: (row: IEducation) => <span className="accessibility_size_table">{row.title}</span>,
            sortable: true,
            wrap: true,
        },
        {
            name: <span className="font-weight-bold accessibility_size_table">Описание</span>,
            selector: (row: IEducation) => row.description,
            cell: (row: IEducation) => <span className="text-muted accessibility_size_table">{row.description}</span>,
            sortable: true,
            wrap: true,
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: IEducation) => (
                <i
                    className="bi bi-plus-lg fs-5 text-black cursor-pointer"
                    onClick={() => navigate(`/education/create_education_content/${row.id}`, { state: { educationTitle: row.title } })}
                    title="Добавить контент"
                />
            ),
            width: '80px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: IEducation) => (
                <i
                    className="bi bi-pencil fs-5 text-black cursor-pointer"
                    onClick={() => handleEditEducation(row)}
                    title="Редактировать"
                />
            ),
            width: '80px',
        },
        {
            name: <span className="font-weight-bold"></span>,
            cell: (row: IEducation) => (
                <i
                    className="bi bi-trash fs-5 text-danger cursor-pointer"
                    onClick={() => handleDeleteEducation(row.id)}
                    title="Удалить"
                />
            ),
            width: '80px',
        },
    ];

    const handleEditEducation = (edu: IEducation) => {
        setEditEducationData(edu);
        setIsModalOpen(true);
    };

    const handleDeleteEducation = (eduId: number) => {
        setEducation(education.filter(edu => edu.id !== eduId));
    };

    const handleAddEducation = () => {
        setEditEducationData(null);
        setIsModalOpen(true);
    };

    const handleSaveEducation = (data: { title: string; description: string }) => {
        if (editEducationData) {
            setEducation(education.map(edu =>
                edu.id === editEducationData.id
                    ? { ...edu, title: data.title, description: data.description }
                    : edu
            ));
        } else {
            const newEducation: IEducation = {
                id: Date.now(),
                image: imgtest,
                title: data.title,
                description: data.description,
                date: new Date(),
            };
            setEducation([...education, newEducation]);
        }
        setEditEducationData(null);
        setIsModalOpen(false);
    };

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Все обучения</title>
            </Helmet>
            <Container fluid>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">Все обучения</h4>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col md={4}>
                                <Label>Поиск по названию:</Label>
                                <Input
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Введите название обучения"
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                        <div>
                            <DataTable
                                columns={columns}
                                data={filteredEducation}
                                noDataComponent="Нет обучений для отображения"
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Обучений на странице:',
                                    rangeSeparatorText: 'из',
                                }}
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </CardBody>
                    <CardFooter className="d-flex justify-content-end align-items-center text-muted">
                        <button className="btn btn-primary" onClick={handleAddEducation}>
                            Добавить обучение
                        </button>
                    </CardFooter>
                </Card>
            </Container>
            <ModalCreateEducation
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(false)}
                onSave={handleSaveEducation}
                initialData={editEducationData}
            />
            <NotificationShow />
        </div>
    );
};

export default EducationPage;